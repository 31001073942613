const RETAILER_ITEM_PRINT_ON_DEMAND_TYPE = 'print_on_demand'

const RETAILER_ITEM_DRAFT = 'draft'
const RETAILER_ITEM_NEW = 'new'
const RETAILER_ITEM_PUBLISHED = 'published'

const RETAILER_ITEM_EXPORT_NOT_READY = 'export_not_ready'
const RETAILER_ITEM_EXPORT_READY = 'export_ready'
const RETAILER_ITEM_EXPORT_IN_PROGRESS = 'export_in_progress'
const RETAILER_ITEM_EXPORT_FAILED = 'export_failed'
const RETAILER_ITEM_EXPORT_HIDDEN = 'export_hidden'
const RETAILER_ITEM_EXPORT_SUCCEEDED = 'export_success'

const SYNC_STATUS_IN_PROGRESS = 'syncInProgress'
const SYNC_STATUS_ERROR = 'failedToSync'
const SYNC_STATUS_SUCCESS = 'synced'

const syncStatusUserFriendly = new Map([
  [SYNC_STATUS_IN_PROGRESS, 'In Progress'],
  [SYNC_STATUS_SUCCESS, 'Synced'],
  [SYNC_STATUS_ERROR, 'Failed'],
])

const syncStatusToRetailerItemExportStatus = new Map([
  [SYNC_STATUS_IN_PROGRESS, RETAILER_ITEM_EXPORT_IN_PROGRESS],
  [SYNC_STATUS_SUCCESS, RETAILER_ITEM_EXPORT_SUCCEEDED + ',' + RETAILER_ITEM_EXPORT_READY],
  [SYNC_STATUS_ERROR, RETAILER_ITEM_EXPORT_FAILED + ',' + RETAILER_ITEM_EXPORT_NOT_READY + ',' + RETAILER_ITEM_EXPORT_HIDDEN],
])

const retailerItemExportStatusToSyncStatus = new Map([
  [RETAILER_ITEM_EXPORT_NOT_READY, SYNC_STATUS_ERROR],
  [RETAILER_ITEM_EXPORT_READY, SYNC_STATUS_SUCCESS],
  [RETAILER_ITEM_EXPORT_IN_PROGRESS, SYNC_STATUS_IN_PROGRESS],
  [RETAILER_ITEM_EXPORT_FAILED, SYNC_STATUS_ERROR],
  [RETAILER_ITEM_EXPORT_HIDDEN, SYNC_STATUS_ERROR],
  [RETAILER_ITEM_EXPORT_SUCCEEDED, SYNC_STATUS_SUCCESS]
])

const PRODUCT_LIST_ORDERING_MOST_RELEVANT = 'mostRelevant'
const PRODUCT_LIST_ORDERING_RECENTLY_ADDED = 'recentlyAdded'
const PRODUCT_LIST_ORDERING_RECENTLY_MODIFIED = 'recentlyModified'
const PRODUCT_LIST_ORDERING_ALPHABETICAL = 'alphabetical'

const productListOrderingUserFriendly = new Map([
  [PRODUCT_LIST_ORDERING_RECENTLY_ADDED, 'Added (newest first)'],
  [PRODUCT_LIST_ORDERING_RECENTLY_MODIFIED, 'Updated (newest first)'],
  [PRODUCT_LIST_ORDERING_ALPHABETICAL, 'Product title A-Z']
])

const syncListOrderingToApiParam = new Map([
  [PRODUCT_LIST_ORDERING_RECENTLY_ADDED, '-published_at'],
  [PRODUCT_LIST_ORDERING_RECENTLY_MODIFIED, '-modified'],
  [PRODUCT_LIST_ORDERING_ALPHABETICAL, 'name']
])

const importListOrderingToApiParam = new Map([
  [PRODUCT_LIST_ORDERING_RECENTLY_ADDED, '-created'],
  [PRODUCT_LIST_ORDERING_RECENTLY_MODIFIED, '-modified'],
  [PRODUCT_LIST_ORDERING_ALPHABETICAL, 'name']
])

const PRODUCT_LIST_FILTERS_INVENTORY_TYPES = new Map([
  ['indieBrands', 'Independent Brands'],
  // ['nameBrands', 'Name Brands'],
  ['trendyItems', 'Trendy, Affordable Items'],
  ['aliexpress', 'Aliexpress'],
  ['alibaba', 'Alibaba'],
  ['beauty', 'Beauty'],
  ['printOnDemand', 'Print on Demand'],
])

const SYNC_LIST_FILTERS_STATUSES = new Map([
  [SYNC_STATUS_SUCCESS, 'filtersPanelSynced.label'],
  [SYNC_STATUS_ERROR, 'filtersPanelSyncFailed.label'],
  [SYNC_STATUS_IN_PROGRESS, 'filtersPanelSyncProgress.label']
])

const ACTION_MOVE_TO_IMPORT_LIST = 'moveToImportList'
const ACTION_REMOVE_FROM_STORE = 'removeFromStore'
const ACTION_AUTO_PRICE_ON = 'autoPriceOn'
const ACTION_AUTO_PRICE_OFF = 'autoPriceOff'
const ACTION_ADD_TO_STORE = 'addToStore'

const actionsUserFriendly = new Map([
  [ACTION_ADD_TO_STORE, 'Add to store'],
  [ACTION_MOVE_TO_IMPORT_LIST, 'Move to the Import List'],
  [ACTION_REMOVE_FROM_STORE, 'Remove from the Store'],
  [ACTION_AUTO_PRICE_ON, 'Enable Auto-price'],
  [ACTION_AUTO_PRICE_OFF, 'Disable Auto-price']
])

const IMPORT_LIST_ITEM_OMIT_FIELDS = [
  'absolute_url',
  'currency_symbol',
  'export_status',
  'main_image_id',
  'store_admin_edit_url',
  'sync_status_badge',
  'weight',
]

const SYNC_LIST_ITEM_OMIT_FIELDS = [
  'tag_ids',
  'absolute_url',
  'category_ids',
  'collection_ids',
  'currency_symbol',
  'main_image_id',
  'product_type_id',
  'supplier_product_shipping',
  'unsure_inventory',
  'weight',
  'variants_count',
  'images'
]

const IMPORT_LIST_PRODUCT_MAX_IMAGES = 50
const IMPORT_LIST_MAX_VARIANTS_WIX = 300
const IMPORT_LIST_MAX_VARIANTS_SHOPIFY = 2048

export {
  RETAILER_ITEM_PRINT_ON_DEMAND_TYPE,
  RETAILER_ITEM_DRAFT,
  RETAILER_ITEM_NEW,
  RETAILER_ITEM_PUBLISHED,
  RETAILER_ITEM_EXPORT_HIDDEN,
  RETAILER_ITEM_EXPORT_IN_PROGRESS,
  RETAILER_ITEM_EXPORT_SUCCEEDED,
  RETAILER_ITEM_EXPORT_READY,
  RETAILER_ITEM_EXPORT_NOT_READY,
  RETAILER_ITEM_EXPORT_FAILED,
  SYNC_STATUS_SUCCESS,
  SYNC_STATUS_IN_PROGRESS,
  SYNC_STATUS_ERROR,
  syncStatusUserFriendly,
  syncStatusToRetailerItemExportStatus,
  retailerItemExportStatusToSyncStatus,
  PRODUCT_LIST_ORDERING_MOST_RELEVANT,
  PRODUCT_LIST_ORDERING_RECENTLY_ADDED,
  PRODUCT_LIST_ORDERING_RECENTLY_MODIFIED,
  PRODUCT_LIST_ORDERING_ALPHABETICAL,
  productListOrderingUserFriendly,
  syncListOrderingToApiParam,
  importListOrderingToApiParam,
  ACTION_MOVE_TO_IMPORT_LIST,
  ACTION_REMOVE_FROM_STORE,
  ACTION_AUTO_PRICE_ON,
  ACTION_AUTO_PRICE_OFF,
  ACTION_ADD_TO_STORE,
  actionsUserFriendly,
  SYNC_LIST_ITEM_OMIT_FIELDS,
  PRODUCT_LIST_FILTERS_INVENTORY_TYPES,
  SYNC_LIST_FILTERS_STATUSES,
  IMPORT_LIST_ITEM_OMIT_FIELDS,
  IMPORT_LIST_PRODUCT_MAX_IMAGES,
  IMPORT_LIST_MAX_VARIANTS_WIX,
  IMPORT_LIST_MAX_VARIANTS_SHOPIFY,
}
